/* src/tailwind.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
ol {
    list-style-type: decimal;
    padding-left:20px;
}

li {
    margin-bottom: 4px;
}