@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Archivo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
@import '@fortawesome/fontawesome-free/css/all.min.css';

@layer base {
  h1 {
    @apply text-4xl md:text-5xl lg:text-6xl mb-4;
  }
  h2 {
    @apply text-3xl md:text-4xl lg:text-5xl mb-3;
  }
  h3 {
    @apply text-2xl md:text-3xl lg:text-4xl mb-3;
  }
  h4 {
    @apply text-xl md:text-2xl lg:text-3xl mb-2;
  }
  h5 {
    @apply text-lg md:text-xl lg:text-2xl mb-2;
  }
  h6 {
    @apply text-base md:text-lg lg:text-xl mb-2;
  }
  p {
    @apply text-base md:text-lg mb-4;
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 255, 255, 255;
  --background-end-rgb: 255, 255, 255;
  --color-green: #00A264;
  --color-blue: #7FC8ED;
  --color-purple: #B780FF;
  --color-pink: #FF6D7D;
  --color-yellow: #FFE126;
  --color-orange: #FF822C;
  --color-white: #FFFFFF;
}

body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-start-rgb));
  font-family: 'Space Grotesk', sans-serif;
}

h1 {
  @apply text-5xl sm:text-7xl ;
  @apply pb-4;
}

h2 {
  @apply text-3xl lg:text-5xl ;
  @apply pb-2;
}

h3 {
  @apply text-2xl lg:text-3xl ;
  @apply pb-2;
}

h6.subtitle {
  @apply text-xl ;
}

p {
  @apply text-lg tracking-wide;
}

.small, .caption {
  @apply text-sm ;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure it's above other elements */
}

.focused-input {
  position: relative; /* or absolute, fixed */
  z-index: 1000; /* Ensure the input is above the overlay */
}

.underline-header {
  position: relative;
  font-style: bold;
  @apply text-xl;
  @apply text-gray-400;
  @apply pb-1;
  @apply font-bold;
}

.underline-header::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  @apply bg-green;
}

ul {
  @apply list-disc pl-3;
}

ol {
  @apply list-decimal pl-3;
}


.markdown-content ul {
  list-style-type: disc; 
  padding-left: 20px;
}

.markdown-content ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.markdown-content li {
  margin-bottom: 10px;
}

.mdx-editor-content {
  border: 1px solid white;
  padding: 24px;
  border-radius: 4px;
}

.modal-open {
  pointer-events: none;
}

.modal-open .modal {
  pointer-events: all;
}

.custom-swiper {
  z-index: 1; /* Lower than the modal's z-index */
}

.swiper-slide {
  width: auto; /* Ensures slide width is based on content */
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 10px;
}

